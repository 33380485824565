<template>
    <div class="availability-new__controls">
        <button
            :class="['availability-new__control-button',
            { 'availability-new__control-button--disabled': Math.min(...this.visibleDays) <= 1 }]"
            @click="prev"
        >
            <NebulaIcon class="availability-new__icon" symbolId="arrow-left-circle" />
            <span class="availability-new__control-button-text">Prev Day</span>
        </button>
        <button
        :class="['availability-new__control-button',
            { 'availability-new__control-button--disabled': Math.max(...this.visibleDays) >= 5 }]"
            @click="next"
        >
            <span class="availability-new__control-button-text">Next Day</span>
            <NebulaIcon class="availability-new__icon" symbolId="arrow-right-circle" />
        </button>
    </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
    name: 'AvailabilityControls',
    components: {
        NebulaIcon,
    },
    props: {
        visibleDays: {
            type: Array,
        },
    },
    methods: {
        prev() {
            this.$emit('prev');
        },
        next() {
            this.$emit('next');
        },
    },
};
</script>
