import createPayload from '@/mixins/data/createPayload';

export default {
    mixins: [createPayload],
    data() {
        return {
            timeSlots: [],
            // this tracks whether a core user preference has changed
            // in this example would be timezone
            // availability is its own API call, only send user updates if there are changes
            userUpdated: false,
            availabilityUpdated: false,
        };
    },
    computed: {
        availabilityLoading() {
            return this.$store.state.status.availability.loading;
        },
    },
    methods: {
        async saveAvailability() {
            const payload = await this.createPayload({ slots: this.timeSlots });
            await this.$store.dispatch('createAvailability', payload);
            this.timeSlots = this.$store.state.user.availability;
        },
        addAvailabilitySlot(slot) {
            this.availabilityUpdated = true;
            this.timeSlots.push(slot);
        },
        removeAvailabilitySlot(slotToRemove) {
            // find matching staged time slot
            const match = this.timeSlots.findIndex((timeSlot) => {
                const keys = Object.keys(timeSlot);

                // create strings with consistent values to compare
                const storedKeyValues = keys.map((timeslotKey) => timeSlot[timeslotKey]).join(',').trim();
                const valuesToRemove = keys.map((timeslotKey) => slotToRemove[timeslotKey]).join(',').trim();

                return storedKeyValues === valuesToRemove;
            });

            if (match !== -1) {
                this.availabilityUpdated = true;
                this.timeSlots.splice(match, 1);
            }
        },
        markUserUpdated() {
            this.userUpdated = true;
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        await this.$store.dispatch('getAvailability', payload);
        this.timeSlots = this.$store.state.user.availability;
    },
    watch: {
        availabilityLoading() {
            this.timeSlots = this.$store.state.user.availability;
        },
    },
};
