<template>
    <NebulaPageWrapper padded contained>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
            >
                <Stepper
                    @update-current-step="updateCurrent"
                    class="profile-setup__stepper"
                    :parentActive="active"
                    :steps="steps"
                    :completed="stepsCompleted"
                    :showIndex="false"
                />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
                v-show="isActive('personal')"
            >
                <Phase
                    class="employee-profile-setup__phase"
                    :data="{
                        header: $t('setup-title', { ns: this.view }),
                        description: $t('instruction', { ns: this.view }),
                        nextText: $t('save-continue'),
                    }"
                    :avatarProps="{
                        image: avatarImage
                    }"
                >
                    <EmployeeProfileStep1 @next-step="updateCurrent()" />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="8"
                :offset="2"
                :mdSize="8"
                v-show="isActive('availability')"
            >
                <Phase
                    class="employee-profile-setup__phase"
                    :data="{
                        header: $t('set-availability', { ns: 'employeeprofile' }),
                        description: $t('when-meet-blob', { ns: 'employeeprofile' }),
                        nextText: $t('save-continue'),
                    }"
                >
                    <EmployeeProfileStep2 @next-step="updateCurrent()" @previous-step="goBack()" />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
                v-show="isActive('expertise')"
            >
                <Phase
                    class="employee-profile-setup__phase"
                    :data="{
                        header: $t('share-expertise', { ns: this.view }),
                        description: $t('get-to-know', { ns: this.view }),
                    }"
                >
                    <EmployeeProfileStep3
                        @next-step="handleExpertiseDone()"
                        @previous-step="goBack()"
                        :nextText="flags['calendar-removed'] ? $t('Save') : $t('save-continue')"
                    />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaPageWrapper v-show="isActive('confirmation')">
            <NebulaGridRow>
                <NebulaGridBlock
                    :columns="6"
                    :offset="3"
                    :mdSize="6"
                    :mdOffset="1"
                    :smSize="4"
                >
                    <Phase
                        class="employee-profile-setup__phase"
                        :data="{
                            header: $t('profile-confirmation-header', { ns: 'employeeprofile' }),
                            description: $t('profile-review', { ns: 'employeeprofile' }),
                        }"
                    >
                        <ProfileSetupActions
                            @click-next="saveProfile"
                            :nextText="$t('Save')"
                            center
                            :analyticsAttributes="{
                                next: {
                                    'data-click-type': 'Profile Setup Workflow: Connector',
                                    'data-click-name': 'Profile Confirmation: Save',
                                },
                            }"
                        >
                            <template v-slot:nextButtons>
                                <NebulaButton
                                    type="ghost"
                                    :text="$t('Edit Profile')"
                                    @click="goBack(0)"
                                    icon-right="edit"
                                    data-click-type="Profile Setup Workflow: Connector"
                                    data-click-name="Profile Confirmation: Edit Profile"
                                />
                            </template>
                        </ProfileSetupActions>
                    </Phase>
                </NebulaGridBlock>
            </NebulaGridRow>
            <NebulaGridRow class="employee-profile-setup__profile" gutter>
                <NebulaGridBlock :columns="6" :mdSize="8" class="employee-profile-setup__profile-panel">
                    <EmployeeProfile
                        preview
                        :data="employeeData"
                    />
                </NebulaGridBlock>
                <NebulaGridBlock :columns="6" :mdSize="8" class="employee-profile-setup__availability-panel">
                    <GeneralAvailability
                        @select-timeslot="addAvailabilitySlot"
                        @deselect-timeslot="removeAvailabilitySlot"
                        :availability="timeSlots"
                        @user-updated="markUserUpdated"
                        :showIcons="false"
                    />
                </NebulaGridBlock>
            </NebulaGridRow>
        </NebulaPageWrapper>
    </NebulaPageWrapper>
</template>

<script>
import { NebulaButton, NebulaGrid } from '@discoveryedu/nebula-components';
import Phase from '@/components/shared/Profile/Phase.vue';
import Stepper from '@/components/shared/stepper/Stepper.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import createPayload from '@/mixins/data/createPayload';

import GeneralAvailability from '@/components/shared/Availability/GeneralAvailability.vue';
import EmployeeProfile from '@/components/dashboard/EmployeeProfile.vue';
import EmployeeProfileStep1 from '@/components/Profile/EmployeeProfileStep1.vue';
import EmployeeProfileStep2 from '@/components/Profile/EmployeeProfileStep2.vue';
import EmployeeProfileStep3 from '@/components/Profile/EmployeeProfileStep3.vue';

import availabilityMixin from '@/mixins/availability/generalAvailability';

export default {
    name: 'EmployeeProfileSetup',
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        Phase,
        Stepper,
        EmployeeProfileStep1,
        EmployeeProfileStep2,
        EmployeeProfileStep3,
        ProfileSetupActions,
        NebulaButton,
        EmployeeProfile,
        GeneralAvailability,
    },
    computed: {
        employeeData() {
            const { data } = this.$store.state.user;
            const { organization } = this.$store.state.app;
            return { ...data, ...{ organization } };
        },
        avatarImage() {
            const { avatarUrl } = this.$store.state.user.data;
            return avatarUrl;
        },
        flags() {
            return this.$store.getters.flags;
        },
        steps() {
            let steps = [
                {
                    name: 'personal',
                    text: 'Setup Profile',
                },
                {
                    name: 'availability',
                    text: 'Add Availability',
                }, {
                    name: 'expertise',
                    text: 'Add Expertise',
                }, {
                    name: 'confirmation',
                    text: 'Done',
                },
            ];

            if (this.flags['calendar-removed']) {
                const availabilityIdx = steps.findIndex((each) => each.name === 'availability');
                steps = steps.toSpliced(availabilityIdx, 1);

                const confirmationIdx = steps.findIndex((each) => each.name === 'confirmation');
                steps = steps.toSpliced(confirmationIdx, 1);
            }

            return steps;
        },
    },
    mixins: [createPayload, availabilityMixin],
    data() {
        return {
            active: 0,
            stepsCompleted: [],
            view: 'employeeprofile',
            saveButtonDisabled: true,
            showErrors: false,
        };
    },
    methods: {
        updateCurrent(idx) {
            this.stepsCompleted.push(this.active);
            if (idx !== undefined) {
                this.active = idx;
            } else if (this.active < this.steps.length - 1) {
                this.active += 1;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        goBack(idx) {
            if (idx !== undefined) {
                this.active = idx;
            } else if (this.active >= 1) {
                this.active -= 1;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async saveProfile() {
            const payload = await this.createPayload();
            if (this.availabilityUpdated) {
                // if availability has been saved
                await this.saveAvailability();
            }
            await this.$store.dispatch('activateEmployee', payload);
            this.$router.push('/');
        },
        isActive(name) {
            const match = this.steps.findIndex((step) => step.name === name);
            return this.active === match;
        },
        async handleExpertiseDone() {
            if (this.flags['calendar-removed']) {
                return this.saveProfile();
            }

            return this.updateCurrent();
        },
    },
};
</script>

<style lang='stylus'>
.employee-profile-setup {
    &__phase {
        margin-block-start: $nebula-space-7x;
    }
    &__profile {
        margin-block-start: $nebula-space-5x;
    }
    &__profile-panel {
        card-base();
    }
    &__availability-panel {
        card-base();
        padding: $nebula-space-3x;
    }
}
</style>
