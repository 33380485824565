var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"availability-new__head"},[_c('h2',{staticClass:"availability-new__header"},[_vm._v(_vm._s(_vm.$t('Dates & Times')))]),_c('TimezoneSelect',{staticClass:"availability-new__timezone-select",on:{"timezone-changed":_vm.updateUserTimezone}})],1),_c('div',{class:['availability-new__column-container',
            {'availability-new__column-container-simple': !this.showIcons}
        ]},[_c('h3',{staticClass:"availability-new__inner-title"},[_vm._v(" My Availability ")]),_c('AvailabilityControls',{attrs:{"visibleDays":_vm.visibleDays},on:{"prev":_vm.prevDay,"next":_vm.nextDay}}),(_vm.showIcons)?_c('div',{staticClass:"availability-new__icon-column"},_vm._l((_vm.timeSlots),function(slot,idx){return _c('div',{key:idx,staticClass:"availability-new__icon-block"},[_c('NebulaIcon',{staticClass:"availability-new__icon",attrs:{"symbolId":_vm.timeIconsMap[slot.startHour]}})],1)}),0):_vm._e(),(_vm.showIcons)?_c('div',{staticClass:"availability-new__hidden-mobile"}):_vm._e(),_vm._l((5),function(x){return _c('h3',{key:_vm.dayOfWeekMap[x - 1],class:[
                'availability-new__column-header',
                { 'availability-new__hidden-mobile': !_vm.visibleInMobile(x) }
            ]},[_vm._v(" "+_vm._s(_vm.dayOfWeekMap[x - 1])+" ")])}),_vm._l((5),function(day){return _c('div',{key:day,class:[
                'availability-new__column',
                { 'availability-new__hidden-mobile': !_vm.visibleInMobile(day) }
            ]},_vm._l((_vm.timeSlots),function(slot,idx){return _c('div',{key:idx,staticClass:"availability-new__button-wrapper"},[_c('AvailabilityButton',{attrs:{"selected":_vm.isSelected(slot, day - 1),"timeSlot":slot},nativeOn:{"click":function($event){return _vm.toggleTimeslot(slot, day - 1)}}})],1)}),0)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }