<template>
    <button :class="classList">
        <div>{{ displayStartTime }}&ndash;{{ displayEndTime }}</div>
        <div class="availability-button__detail" v-if="detail">{{ detail }}</div>
    </button>
</template>

<script>
export default {
    name: 'AvailabilityButton',
    props: {
        timeSlot: {
            type: Object,
        },
        selected: {
            type: Boolean,
        },
    },
    computed: {
        displayStartTime() {
            const numeral = this.convertTo12Hour(this.timeSlot.startHour);
            const suffix = this.meridiem(this.timeSlot.startHour);
            return `${numeral}${suffix}`;
        },
        displayEndTime() {
            const numeral = this.convertTo12Hour(this.timeSlot.endHour);
            const suffix = this.meridiem(this.timeSlot.endHour);
            return `${numeral}${suffix}`;
        },
        detail() {
            const detailMap = {
                8: 'Morning',
                10: 'Late Morning',
                12: 'Afternoon',
                14: 'Late Afternoon',
            };

            return detailMap[this.timeSlot.startHour];
        },
        classList() {
            return [
                'availability-button',
                { 'availability-button--selected': this.selected },
            ];
        },
    },
    methods: {
        meridiem(int) {
            return int >= 12 ? 'pm' : 'am';
        },
        convertTo12Hour(int) {
            if (int === 12) {
                return 12;
            }
            return int % 12;
        },
    },
};
</script>

<style lang="stylus">
.availability-button {
    border-radius: $nebula-border-radius-small;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 16px;
    transition: $nebula-transition-default;
    padding: $nebula-space-1x;
    line-height: 1em;
    width: 100%;
    height: 100%;
    @media (min-width: $nebula-breakpoints-desktop) {
        &:hover {
            background: $nebula-color-platform-interactive-200;
            color: $nebula-color-platform-interactive-900;
        }
    }

    &--selected {
        background: $nebula-color-platform-interactive-200;
        color: $nebula-color-platform-interactive-900;
    }

    &__detail {
        font-size: 14px;
        font-weight: 700;
        margin-block-start: $nebula-space-half;
    }
}
</style>
