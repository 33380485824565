<template>
    <NebulaFieldGroup
        class="timezone-input"
        inputId="timezone"
        :aria-label="$t('time-zone')"
        :labelText="$t('Time Zone')"
        :labelPosition="!isMobile ? 'inline' : ''"
    >
        <template v-slot:formElement>
            <div>
                <NebulaSelect
                    class="timezone-input__select"
                    size="s"
                    aria-describedby="helper-text__hidden-helper-timezone"
                    id="timezone"
                >
                    <template v-slot:select>
                        <select
                            class="nebula-select__element"
                            id="tenure"
                            ref="selectTimezoneRef"
                            @input="updateTimezone"
                        >
                            <NebulaSelectOption
                                v-for="(option, index) in timezoneOptions"
                                :option="option"
                                :key="index"
                            />
                        </select>
                    </template>
                </NebulaSelect>
            </div>
        </template>
    </NebulaFieldGroup>
</template>

<script>
import {
    NebulaFieldGroup,
    NebulaSelect,
    NebulaSelectOption,
} from '@discoveryedu/nebula-components';
import breakpoints from '@/mixins/breakpoints';

export default {
    name: 'TimezoneSelect',
    components: {
        NebulaFieldGroup,
        NebulaSelect,
        NebulaSelectOption,
    },
    mixins: [breakpoints],
    data() {
        return {
            localTimezone: '',
        };
    },
    computed: {
        timezoneOptions() {
            return [
                { value: 'America/New_York', text: this.$t('timezone-est', { ns: 'dropdown' }) },
                { value: 'America/Chicago', text: this.$t('timezone-cst', { ns: 'dropdown' }) },
                { value: 'America/Denver', text: this.$t('timezone-mst', { ns: 'dropdown' }) },
                { value: 'America/Los_Angeles', text: this.$t('timezone-pst', { ns: 'dropdown' }) },
            ];
        },
        storedTimezone() {
            return this.$store.state.user.data.timezone;
        },
        initial() {
            return this.storedTimezone || this.localTimezone;
        },
        loading() {
            return this.$store.state.status.user.loading;
        },
    },
    methods: {
        updateTimezone(e) {
            this.$emit('timezone-changed', e.target.value);
        },
    },
    async mounted() {
        // default to local timezone on mount
        this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.$refs.selectTimezoneRef.value = this.initial;
    },
    watch: {
        initial() {
            this.$refs.selectTimezoneRef.value = this.initial;
        },
        loading(loading) {
            if (!loading) {
                // on initial load, if the user has no stored timezone, update it
                this.$emit('timezone-changed', this.initial);
            }
        },
    },
};
</script>

<style lang="stylus">
.timezone-input {
    width: 100%;
    &__select {
        width: 100%;
    }
    @media (min-width: $nebula-breakpoints-desktop) {
        width: auto;
    }
}
</style>
