<template>
    <EmployeeProfileSetup v-if="appMode == 'employee'" />
    <EducatorProfileSetup v-else-if="appMode == 'educator'" />
    <EmployerProfileSetup v-else />
</template>

<script>
import EmployeeProfileSetup from '@/components/Profile/EmployeeProfileSetup.vue';
import EmployerProfileSetup from '@/components/Profile/EmployerProfileSetup.vue';
import EducatorProfileSetup from '@/components/Profile/Educator/ProfileSetup.vue';

export default {
    name: 'ProfileSetupNew',
    components: {
        EmployeeProfileSetup,
        EmployerProfileSetup,
        EducatorProfileSetup,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
    },
    methods: {
        // TODO: replace with validation
        updateCurrent(idx) {
            if (idx !== undefined) {
                this.active = idx;
            } else if (this.active < this.steps - 1) {
                this.active += 1;
            }
        },
        goBack(idx) {
            if (idx) {
                this.active = idx;
            } else if (this.active >= 1) {
                this.active -= 1;
            }
        },
    },
};
</script>
