<template>
    <span>
        {{ text }}
        <a :href="link"
            class="checkbox-label__link"
            target="_blank" rel="noopener noreferrer"
        >
            {{ anchor }}
        </a>
    </span>
</template>

<script>
export default {
    name: 'CheckboxLabel',
    props: {
        text: String,
        link: String,
        anchor: String,
    },
};
</script>

<style lang="stylus">
.checkbox-label {
    &__link {
        color: $nebula-color-platform-interactive-800;
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
