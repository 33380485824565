var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NebulaPageWrapper',{attrs:{"padded":"","contained":""}},[_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Stepper',{staticClass:"profile-setup__stepper",attrs:{"parentActive":_vm.active,"steps":_vm.steps,"showIndex":true,"completed":_vm.stepsCompleted},on:{"update-current-step":_vm.updateCurrent}})],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 0),expression:"active === 0"}],attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employer-profile-setup__phase",attrs:{"data":{
                    header: _vm.$t('setup-title', { ns: 'profile' }),
                    description: _vm.$t('instruction', { ns: 'profile' }),
                    nextText: _vm.$t('save-continue'),
                },"avatarProps":{
                    image: _vm.avatarImage,
                    icon: 'buildings',
                },"isOrganization":true}},[_c('EmployerProfileStep1',{on:{"next-step":function($event){return _vm.updateCurrent()}}})],1)],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 1),expression:"active === 1"}],attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employer-profile-setup__phase",attrs:{"data":{
                    header: _vm.$t('add-users', { ns: 'profile' }),
                    nextText: _vm.$t('save-continue'),
                    description:
                        `Get started by inviting employees (or members for professional organizations) to join your organization's
                        account. You can manage this list later from the User Management page.`,
                }}},[_c('EmployerProfileStep2',{on:{"previous-step":_vm.goBack,"skip":function($event){return _vm.updateCurrent()},"next-step":function($event){return _vm.updateCurrent()}}})],1)],1)],1),_c('NebulaPageWrapper',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}]},[_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employer-profile-setup__phase",attrs:{"data":{
                        header: _vm.$t('your-company-profile', { ns: 'profile' }),
                    }},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( `Please review your public facing profile before saving. You can manage and edit your profile information at any time in`, ))+" "),_c('a',{staticClass:"employer-profile__link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('Settings & Preferences'))+".")])]},proxy:true}])},[_c('ProfileSetupActions',{attrs:{"nextText":_vm.$t('Save'),"center":"","analyticsAttributes":{
                            next: {
                                'data-click-type': 'Profile Setup Workflow: Org Admin',
                                'data-click-name': 'Profile Confirmation: Save',
                            },
                        }},on:{"click-next":_vm.saveProfile},scopedSlots:_vm._u([{key:"nextButtons",fn:function(){return [_c('NebulaButton',{attrs:{"type":"ghost","text":_vm.$t('Edit Profile'),"icon-right":"edit","data-click-type":"Profile Setup Workflow: Org Admin","data-click-name":"Profile Confirmation: Edit Profile"},on:{"click":function($event){return _vm.goBack(0)}}})]},proxy:true}])})],1)],1)],1),_c('NebulaGridRow',{staticClass:"employee-profile-setup__profile",attrs:{"gutter":""}},[_c('NebulaGridBlock',{staticClass:"employer-profile-setup__profile-panel",attrs:{"columns":12}},[_c('CompanyProfile',{attrs:{"preview":"","data":_vm.orgData}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }