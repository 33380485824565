<template>
    <div>
        <ErrorBanner
            v-if="this.showErrors && this.fieldsWithErrors.length > 0"
            header="Form Errors"
            description="Please resolve the form errors in order to proceed to the next step."
            color="red"
        />
        <ErrorBanner
            v-if="this.showErrors && this.fieldsWithErrors.includes('termsChecked') || this.fieldsWithErrors.includes('privacyChecked')"
            header="Errors"
            description="You must accept the terms and conditions to sign up for an account."
            color="red"
        />
        <ProfileFormSection header="Personal Details">
            <ProfileFieldGroup horizontal>
                <ProfileFormField
                    name="firstName"
                    :inputGroupData="formData.firstName"
                    :validation="validateInput"
                />
                <ProfileFormField
                    name="lastName"
                    :inputGroupData="formData.lastName"
                    :validation="validateInput"
                />
            </ProfileFieldGroup>
            <ProfileFieldGroup horizontal>
                <ProfileFormField
                    name="city"
                    :inputGroupData="formData.city"
                    :validation="validateInput"
                    :flex="3"
                />
                <ProfileFormField
                    name="state"
                    :inputGroupData="formData.state"
                    :validation="validateInput"
                    :selectOptions="stateOptions"
                />
            </ProfileFieldGroup>
        </ProfileFormSection>
        <ProfileFormSection header="Identity">
            <ProfileFieldGroup horizontal>
                <ProfileFormField
                    name="preferredName"
                    :inputGroupData="formData.preferredName"
                    :validation="validateInput"
                    :helperText="$t('This will appear as your first name on your profile.')"
                />
                <ProfileFormField
                    name="pronouns"
                    :inputGroupData="formData.pronouns"
                    :validation="validateInput"
                    :selectOptions="pronounsOptions"
                />
            </ProfileFieldGroup>
            <ProfileFormField
                inputType="multi-select"
                name="languages"
                :key="formData.languages.value.length"
                :inputGroupData="formData.languages"
                :validation="validateInput"
                :selectOptions="languageOptions"
                @update-multi-select="handleMultiSelect"
            />
            <ProfileFormField
                name="race"
                inputType="select"
                :inputGroupData="formData.race"
                :validation="validateInput"
                :selectOptions="raceOptions"
            />
            <ProfileFormField
                name="gender"
                inputType="select"
                :inputGroupData="formData.gender"
                :validation="validateInput"
                :selectOptions="genderOptions"
            />
        </ProfileFormSection>
        <ProfileFormSection header="Professional Details">
            <p class="employee-profile-setup__prepop-label">{{ $t('organization', { ns: 'employeeprofile' }) }}</p>
            <p class="employee-profile-setup__prepop-value">{{ currentOrganization }}</p>
            <ProfileFormField
                name="title"
                :inputGroupData="formData.title"
                :validation="validateInput"
                :helperText="$t('This is your current position/title for your professional career.' +
                ' We’ll display this on your public profile.')"
            />
            <ProfileFormField
                name="industry"
                inputType="select"
                :inputGroupData="formData.industry"
                :validation="validateInput"
                :selectOptions="industryOptions"
                :helperText="$t('Choose the industry most closely related to your professional career.')"
            />
            <ProfileFormField
                name="level"
                inputType="select"
                :inputGroupData="formData.level"
                :selectOptions="employmentLevelOptions"
                :validation="validateInput"
            />
            <ProfileFormField
                name="tenure"
                inputType="select"
                :inputGroupData="formData.tenure"
                :selectOptions="tenureOptions"
                :validation="validateInput"
            />
        </ProfileFormSection>

        <ProfileFormSection v-if="!isActive">
            <ProfileFieldGroup
                padded
            >
                <p class="employee-profile-setup__prepop-label">
                    {{ $t('terms-and-conditions-label', { ns: 'termsconditions' }) }}
                    <span class="nebula-label__required">*</span>
                </p>
                <ProfileFormField
                    name="terms"
                    inputType="check-box"
                    :inputGroupData="formData.privacyChecked"
                    :validation="validateInput"
                >
                    <template v-slot:checkbox-label>
                        <CheckboxLabel
                            :text="$t('connector-terms-text', { ns: 'termsconditions' })"
                            :link="$t('terms-of-use-link', { ns: 'termsconditions' })"
                            :anchor="$t('terms-of-use-anchor', { ns: 'termsconditions' })"
                        />
                    </template>
                </ProfileFormField>
                <ProfileFormField
                    name="privacy"
                    inputType="check-box"
                    :inputGroupData="formData.termsChecked"
                    :validation="validateInput"
                >
                    <template v-slot:checkbox-label>
                        <CheckboxLabel
                            :text="$t('connector-privacy-text', { ns: 'termsconditions' })"
                            :link="$t('privacy-policy-link', { ns: 'termsconditions' })"
                            :anchor="$t('privacy-policy-anchor', { ns: 'termsconditions' })"
                        />
                    </template>
                </ProfileFormField>
            </ProfileFieldGroup>
        </ProfileFormSection>

        <ProfileSetupActions
            @click-next="handleSaveAndContinue"
            :nextDisabled="saveProcessing"
            :nextText="saveProcessing ? 'Processing' : undefined"
            :analyticsAttributes="{
                next: {
                    'data-click-type': 'Profile Setup Workflow: Connector',
                    'data-click-name': 'Connector Details: Save & Continue',
                },
            }"
        />
    </div>
</template>

<script>
import ProfileFormSection from '@/components/shared/Profile/ProfileFormSection.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileFieldGroup from '@/components/shared/Profile/ProfileFieldGroup.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import ErrorBanner from '@/components/shared/ErrorBanner.vue';
import CheckboxLabel from '@/components/shared/forms/CheckboxLabel.vue';

import {
    employmentLevelOptions,
    genderOptions,
    industryOptions,
    languageOptions,
    pronounsOptions,
    raceOptions,
    stateOptions,
    tenureOptions,
} from '@/data/formData';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'EmployeeProfileStep1',
    components: {
        CheckboxLabel,
        ProfileFormSection,
        ProfileFormField,
        ProfileFieldGroup,
        ProfileSetupActions,
        ErrorBanner,
    },
    mixins: [createPayload],
    computed: {
        pronounsOptions,
        raceOptions,
        genderOptions,
        languageOptions,
        industryOptions,
        employmentLevelOptions,
        tenureOptions,
        currentOrganization() {
            // TODO how do we detect the active organization if all users can be in multiple?
            return this.$store.state.app.organization.name;
        },
        loading() {
            return this.$store.state.status.user.loading;
        },
        isActive() {
            return this.$store.state.user.data.isActive;
            // return false;
        },

    },

    data() {
        return {
            stateOptions,
            showErrors: false,
            fieldsWithErrors: [],
            saveProcessing: false,
            formData: {
                firstName: {
                    value: '',
                    display: this.$t('first-name'),
                    required: true,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                lastName: {
                    value: '',
                    display: this.$t('last-name'),
                    required: true,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                languages: {
                    value: [],
                    display: this.$t('language'),
                    required: true,
                    error: null,
                    status: '',
                },
                city: {
                    value: '',
                    display: this.$t('city'),
                    required: true,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: false,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                state: {
                    value: '',
                    display: this.$t('state'),
                    required: true,
                    error: null,
                    status: '',
                },
                preferredName: {
                    value: '',
                    display: this.$t('preferred-name'),
                    required: false,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: false,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                pronouns: {
                    value: '',
                    display: this.$t('pronouns'),
                    required: false,
                    error: null,
                    status: '',
                },
                race: {
                    value: '',
                    display: this.$t('race-ethnicity'),
                    required: false,
                    error: null,
                    status: '',
                },
                gender: {
                    value: '',
                    display: this.$t('gender'),
                    required: false,
                    error: null,
                    status: '',
                },
                title: {
                    value: '',
                    display: this.$t('title'),
                    required: true,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                industry: {
                    value: '',
                    display: this.$t('industry'),
                    required: true,
                    error: null,
                    status: '',
                },
                level: {
                    value: '',
                    display: this.$t('level'),
                    required: true,
                    error: null,
                    status: '',
                },
                tenure: {
                    value: '',
                    display: this.$t('tenure'),
                    required: true,
                    error: null,
                    status: '',
                },
                zip: {
                    value: '',
                    display: this.$t('zip-code'),
                    required: false,
                    validation: {
                        dataLength: 5,
                        numberOnly: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                termsChecked: {
                    value: false,
                    required: true,
                    error: null,
                    status: '',
                },
                privacyChecked: {
                    value: false,
                    required: true,
                    error: null,
                    status: '',
                },
            },
        };
    },
    methods: {
        handleMultiSelect(data) {
            this.formData[data.name].value = data.values;
        },
        async handleSaveAndContinue() {
            this.saveProcessing = true;
            this.fieldsWithErrors = this.validateAll();
            const payload = await this.createPayload();

            if (this.fieldsWithErrors.length > 0) {
                this.showErrors = true;
                this.fieldsWithErrors.forEach((field) => {
                    this.formData[field].status = 'error';
                });
                this.saveProcessing = false;
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                const storeable = {};
                Object.keys(this.formData).forEach((field) => {
                    storeable[field] = this.formData[field].value;
                });

                // store content if validation passes
                this.$store.dispatch('updateUserData', storeable);
                this.$store.dispatch('saveEmployee', payload);
                this.saveProcessing = false;
                this.$emit('next-step');
            }
        },
        validateAll() {
            const fields = Object.keys(this.formData);
            fields.forEach((field) => {
                this.validateInput(field);
            });

            return fields.filter((each) => this.formData[each].error);
        },
        containsSpecialCharacters(str) {
            const regex = /[!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?]/g;
            return regex.test(str);
        },
        isValid(key) {
            const { value, required, validation } = this.formData[key];

            // if field is required, not valid without content
            if (required) {
                if (!value) {
                    return false;
                }
            }

            // if has a validation and has value, validate
            if (validation && value) {
                if (validation.dataLength) {
                    if (value && value.length !== validation.dataLength) {
                        this.formData[key].errorString = `Only ${validation.maxDataLength} characters allowed`;
                        return false;
                    }
                }
                if (validation.numberOnly) {
                    if (!Number.isFinite(value)) {
                        this.formData[key].errorString = 'Only numbers are allowed';
                        return false;
                    }
                }
                if (validation.maxDataLength) {
                    if (value && value.length > validation.maxDataLength) {
                        this.formData[key].errorString = `Only less then ${validation.maxDataLength} characters allowed`;
                        return false;
                    }
                }
                if (!validation.specialCharactersAllowed) {
                    if (this.containsSpecialCharacters(value)) {
                        this.formData[key].errorString = 'No special characters allowed';
                        return false;
                    }
                }
            }
            return true;
        },
        validateInput(key) {
            const passes = this.isValid(key);
            // 'status' is for the purpose of display state only
            // only update the status for an individual field if errors should be visible
            if (this.showErrors) {
                const errorString = 'error';

                this.formData[key].status = !passes ? errorString : null;
            }

            if (passes && this.fieldsWithErrors.includes(key)) {
                const match = this.fieldsWithErrors.findIndex((each) => each === key);
                this.fieldsWithErrors.splice(match, 1);
            }

            this.formData[key].error = !passes;
        },
        async populateFields() {
            const requiredForInactive = ['termsChecked', 'privacyChecked'];

            const userData = this.$store.state.user.data;
            const fields = Object.keys(userData);
            const enumFields = ['race', 'gender', 'industry', 'level', 'tenure'];
            const inputFields = fields.filter((each) => !enumFields.includes(each));
            // enum/select fields that need some standardization
            enumFields.forEach((each) => {
                if (this.formData[each]) {
                    const incomingValue = userData[each];
                    // temp: don't change case of `healthScience`
                    if (incomingValue === 'healthScience') {
                        this.formData[each].value = incomingValue;
                    } else {
                        this.formData[each].value = incomingValue ? incomingValue.toLowerCase() : '';
                    }
                }
            });
            inputFields.forEach((each) => {
                if (this.formData[each]) {
                    this.formData[each].value = userData[each];
                }
            });

            // on edit profile, update required fields as needed
            if (this.isActive) {
                requiredForInactive.forEach((key) => {
                    this.formData[key].required = false;
                });
            }
            return this.validateAll();
        },
    },
    watch: {
        loading(loading) {
            if (!loading) {
                this.populateFields();
            }
        },
    },
    mounted() {
        this.populateFields();
    },
};
</script>

<style lang='stylus'>
.employee-profile-setup {
    &__prepop-label {
        prepop-label();
    }

    &__prepop-value {
        prepop-value();
    }
}
</style>
