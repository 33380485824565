<template>
    <div>
        <Banners :data="errors" />
        <InviteInput
            :inputGroupData="formData.invite"
            :updateErrors="updateErrors"
            :stagedFromParent="this.invites"
            inviteButtonText="Add Users"
            @stage-invites="stageInvites"
            :showCounter="false"
        >
            <template v-slot:helper>
                All users will be added as <em>members</em>, by default. There is a maximum of {{ INVITE_MAX }} employee invites at a time.
            </template>
        </InviteInput>
        <table class="employer-profile__invite-list">
            <thead v-if="invites && invites.length > 0">
                <tr>
                    <th class="employer-profile__invite-header">{{ $t('User') }}</th>
                    <th class="employer-profile__invite-header">{{ $t('Access Level') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="employer-profile__invite-row" v-for="(invite, idx) in invites" :key="idx">
                    <td>
                        <p class="employer-profile__email">{{ invite }}</p>
                    </td>
                    <td class="employer-profile__invite-name">
                        {{ $t('Employee') }}
                        <NebulaIconButton
                            aria-label="remove from list"
                            icon="circle-minus"
                            type="flat"
                            class="employer-profile-remove"
                            @click="removeInvite(invite)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <ProfileSetupActions
            @click-next="handleSaveAndContinue"
            @click-back="goBack"
            :nextText="nextText"
            :nextDisabled="invites.length > INVITE_MAX || invites.length < 1"
            showBack
            :analyticsAttributes="{
                next: {
                    'data-click-type': 'Profile Setup Workflow: Org Admin',
                    'data-click-name': 'Invites: Send',
                },
                back: {
                    'data-click-type': 'Profile Setup Workflow: Org Admin',
                    'data-click-name': 'Invites: Back',
                }
            }"
        >
            <template v-slot:nextButtons>
                <NebulaButton
                    type="ghost"
                    :text="$t('skip')"
                    @click="skip()"
                    data-click-type="Profile Setup Workflow: Org Admin"
                    data-click-name="Invites: Skip"
                />
            </template>
        </ProfileSetupActions>
    </div>
</template>

<script>
import {
    NebulaIconButton,
    NebulaButton,
} from '@discoveryedu/nebula-components';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import Banners from '@/components/UserManagement/Banners.vue';
import InviteInput from '@/components/shared/forms/InviteInput.vue';

import pluralize from '@/utils/pluralize';
import createPayload from '@/mixins/data/createPayload';
import { INVITE_MAX } from '@/constants';

export default {
    name: 'EmployerProfileStep2',
    components: {
        NebulaIconButton,
        NebulaButton,
        ProfileSetupActions,
        Banners,
        InviteInput,
    },
    mixins: [createPayload],
    computed: {
        loading() {
            return this.$store.state.status.invites.loading;
        },
    },
    data() {
        return {
            INVITE_MAX,
            nextText: 'Send Invites',
            formData: {
                invite: {
                    value: '',
                },
            },
            invites: [],
            errors: {
                invalid: [],
                duplicate: [],
            },
            accountManagementLink: '#',
            invalidEmails: [],
            showConfirm: false,
        };
    },
    methods: {
        async handleSaveAndContinue() {
            const payload = await this.createPayload(this.invites);

            await this.$store.dispatch('createOrganizationEmployees', payload);
            this.$emit('next-step');
        },
        async sendInvites() {
            this.convertToInvites();
        },
        updateErrors(key, valueToAdd) {
            this.errors[key].push(valueToAdd);
        },
        stageInvites(invites) {
            this.invites = [...this.invites, ...invites];
            this.setNextText();
            this.formData.invite.value = '';
        },
        setNextText() {
            if (this.invites.length > INVITE_MAX) {
                this.nextText = `${this.invites.length}/${INVITE_MAX} Invites`;
                return;
            }
            this.nextText = `Send ${this.invites.length} ${pluralize('Invite', this.invites.length)}`;
        },
        removeInvite(invite) {
            const match = this.invites.findIndex((each) => each.email === invite.email);
            this.invites.splice(match, 1);
            this.setNextText();
        },
        goBack() {
            this.$emit('previous-step');
        },
        skip() {
            this.$emit('skip');
        },
    },
    watch: {
        async loading(loading) {
            if (!loading) {
                this.$emit('next-step');
            }
        },
    },
};
</script>

<style lang="stylus">
.employer-profile {
    &-remove {
        margin-inline-start: $nebula-space-3x;
        padding: 0;
    }

    &__invite-header {
        font-size: 18px;
        padding-bottom: $nebula-space-1x;
        padding-top: $nebula-space-1x;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }

    &__invite-list {
        border-bottom: 1px solid $nebula-color-platform-interface-400;
        margin-bottom: $nebula-space-6x;
        width: 100%;

        td {
            padding-bottom: 14px;
            padding-top: 14px;

            &:first-child {
                align-items: center;
                justify-content: flex-start;
            }
            &:last-child {
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &__invite-row {
        border-top: 1px solid $nebula-color-platform-interface-400;
    }

    &__email {
        color: $nebula-color-platform-interactive-800;
        text-decoration: underline;
        margin: 0;
    }
}

.nebula-input-with-buttons__input {
    flex: 1;
}
</style>
