var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GeneralAvailability',{staticClass:"employee-profile-setup__general-availability",attrs:{"availability":_vm.timeSlots},on:{"select-timeslot":_vm.addAvailabilitySlot,"deselect-timeslot":_vm.removeAvailabilitySlot,"user-updated":_vm.markUserUpdated}}),_c('ProfileSetupActions',{attrs:{"nextDisabled":_vm.saveProcessing,"nextText":_vm.saveProcessing ? 'Processing' : undefined,"showBack":"","analyticsAttributes":{
            next: {
                'data-click-type': 'Profile Setup Workflow: Connector',
                'data-click-name': 'Availability: Save',
            },
            back: {
                'data-click-type': 'Profile Setup Workflow: Connector',
                'data-click-name': 'Availability: Back',
            },
        }},on:{"click-next":_vm.handleSaveAndContinue,"click-back":_vm.goBack}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }