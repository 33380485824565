<template>
    <div>
        <GeneralAvailability
            class="employee-profile-setup__general-availability"
            @select-timeslot="addAvailabilitySlot"
            @deselect-timeslot="removeAvailabilitySlot"
            :availability="timeSlots"
            @user-updated="markUserUpdated"
        />
        <ProfileSetupActions
            @click-next="handleSaveAndContinue"
            @click-back="goBack"
            :nextDisabled="saveProcessing"
            :nextText="saveProcessing ? 'Processing' : undefined"
            showBack
            :analyticsAttributes="{
                next: {
                    'data-click-type': 'Profile Setup Workflow: Connector',
                    'data-click-name': 'Availability: Save',
                },
                back: {
                    'data-click-type': 'Profile Setup Workflow: Connector',
                    'data-click-name': 'Availability: Back',
                },
            }"
        />
    </div>
</template>

<script>
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import GeneralAvailability from '@/components/shared/Availability/GeneralAvailability.vue';
import createPayload from '@/mixins/data/createPayload';

import availabilityMixin from '@/mixins/availability/generalAvailability';

export default {
    name: 'EmployeeProfileStep2',
    components: {
        GeneralAvailability,
        ProfileSetupActions,
    },
    mixins: [createPayload, availabilityMixin],
    data() {
        return {
            saveButtonDisabled: false,
            showErrors: false,
            timeSlots: [],
            // this tracks whether a core user preference has changed
            // in this example would be timezone
            // availability is its own API call, only send user updates if there are changes
            userUpdated: false,
            saveProcessing: false,
        };
    },
    methods: {
        async handleSaveAndContinue() {
            this.saveProcessing = true;

            // if user object has been updated, save it
            if (this.userUpdated) {
                const payload = await this.createPayload();
                await this.$store.dispatch('saveEmployee', payload);
            }
            // if availability has been updated, save it
            if (this.userUpdated || this.availabilityUpdated) {
                await this.saveAvailability();
            }

            this.saveProcessing = false;
            this.$emit('next-step');
        },
        goBack() {
            this.$emit('previous-step');
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        await this.$store.dispatch('getAvailability', payload);
        this.timeSlots = this.$store.state.user.availability;
    },
};
</script>

<style lang="stylus">
.employee-profile-setup {
    &__general-availability {
        @media (min-width: $nebula-breakpoints-desktop) {
            flat-card-base();
            padding: $nebula-space-3x;
        }
    }
}
</style>
