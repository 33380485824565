<template>
    <div v-if="hasError">
        <NebulaBlockMessage
            v-if="showBanner('invalid')"
            class="user-management__block-message"
            icon="warning--filled"
            header="Invalid Emails"
            color="orange"
            :description="invalidDescription"
        />
        <NebulaBlockMessage
            v-if="showBanner('duplicate')"
            class="user-management__block-message"
            icon="warning--filled"
            header="Duplicate Emails"
            :description="duplicateDescription"
        />
        <NebulaBlockMessage
            v-if="showBanner('limit')"
            class="user-management__block-message"
            icon="warning--filled"
            header="Limit exceeded"
            :description="limitDescription"
        />
    </div>
</template>

<script>
import { NebulaBlockMessage } from '@discoveryedu/nebula-components';
import { INVITE_MAX } from '@/constants';

export default {
    name: 'Banners',
    components: {
        NebulaBlockMessage,
    },
    computed: {
        hasError() {
            const errorTypes = Object.keys(this.data);

            const present = errorTypes.find((type) => this.data[type].length > 0);
            return !!present;
        },
        duplicateDescription() {
            const { duplicate } = this.data;
            return `Duplicate Email(s) found: ${duplicate.join(', ')}. Duplicates have been combined to a single invite.`;
        },
        invalidDescription() {
            const { invalid } = this.data;
            return `Email(s) "${invalid.join('", "')}" were invalid. Please re-enter these email addresses to send them a user invitation.`;
        },
        limitDescription() {
            const { limit } = this.data;
            const [attempted] = limit;
            return `You invited ${attempted} employees. There is a maximum limit of ${INVITE_MAX} invites that can be sent at a time.`;
        },
    },
    props: {
        data: {
            type: Object,
        },
    },
    methods: {
        showBanner(key) {
            return this.data[key] && this.data[key].length > 0;
        },
    },
};
</script>

<style lang="stylus">
.user-management__block-message {
    max-width: unset;
    margin-block-end: $nebula-space-3x;
}
</style>
