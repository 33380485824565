<template>
    <div class="stepper">
        <div :key="idx" v-for="step, idx in steps" :class="stepClass(idx)">
            <component
                :is="clickable ? 'button' : 'div'"
                class="step-button"
                @click="clickable && handleClick(idx)"
            >
                <span class="step-number__container">
                    <NebulaIcon class="stepper__icon" v-if="isCompleted(idx)" symbol-id="check" size="s" />
                    <span v-else class="step-number__glyph">
                        {{ idx + 1 }}
                    </span>
                </span>
                <div class="step-description">
                    {{ displayText(step.text, idx) }}
                </div>
            </component>
        </div>
    </div>
</template>
<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
    name: 'Stepper',
    components: {
        NebulaIcon,
    },
    data() {
        return {
            active: this.parentActive || 0,
        };
    },
    methods: {
        isCompleted(idx) {
            return idx !== this.active && this.completed.includes(idx);
        },
        stepClass(idx) {
            return [
                'step',
                {
                    'step--active': idx === this.active,
                    'step--button': this.clickable,
                    'step--complete': idx !== this.active && this.isCompleted(idx),
                },
            ];
        },
        displayText(baseText, idx) {
            const desc = baseText || this.$t('step');
            if (this.showIndex) {
                return this.$t(`${desc} ${idx + 1}`);
            }
            return this.$t(desc);
        },
        handleClick(idx) {
            this.active = idx;
            this.$emit('update-current-step', idx);
        },
    },
    props: {
        parentActive: {
            type: Number,
        },
        showIndex: {
            type: Boolean,
            default: true,
        },
        steps: {
            type: [Array, Number],
            default() {
                return [];
            },
        },
        completed: {
            type: Array,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        parentActive(newValue) {
            this.active = newValue;
        },
    },
};
</script>

<style lang="stylus">
    $stepper-number-size = 28px;
    .stepper {
        display: flex;
        font-weight: 600;
        width: 100%;
        &__icon {
            fill: $nebula-color-platform-white;
        }
    }
    .step {
        align-items: center;
        display: flex;
        flex-grow: 1;

        &:last-child {
            flex-grow: 0;
            &:after {
                display: none;
            }
        }

        &:after {
            display: block;
            content: '';
            flex-grow: 1;
            margin: 0 $nebula-space-2x;
            border-bottom: 2px solid $nebula-color-platform-interactive-800;
        }

        &--active {
            .step-number__container {
                background-color: $nebula-color-platform-interactive-800;
            }
            .step-number__glyph {
                color: $nebula-color-platform-white;
            }
        }

        &--complete {
            &:after {
                border-color: $nebula-color-feedback-success-600;
            }
            .step-number__container {
                background-color: $nebula-color-feedback-success-600;
                border-color: $nebula-color-feedback-success-700;
            }
            .step-number__glyph {
                color: $nebula-color-platform-white;
            }
        }

        &-button {
            align-items: center;
            background: none;
            border: none;
            display: flex;
            padding: 0;
        }

        &-description {
            display: none;
            font-size: $nebula-font-size-caption;
            font-weight: 400;
            @media (min-width: $nebula-breakpoints-tablet-landscape) {
                display: block;
            }
        }

        &-number {
            &__container {
                nebula-text-caption();
                align-items: center;
                border: 2px solid $nebula-color-platform-interactive-800;
                border-radius: 50%;
                color: $nebula-color-platform-neutral-900;
                display: flex;
                height: $stepper-number-size;
                justify-content: center;
                transition: $nebula-transition-default;
                width: $stepper-number-size;

                @media (min-width: $nebula-breakpoints-tablet-landscape) {
                    margin-right: $nebula-space-1x;
                }
            }
            &__glyph {
                display: block;
                font-weight: 600;
                line-height: 0;
                color: $nebula-color-platform-interactive-800;
            }
        }
    }
</style>
