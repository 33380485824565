var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Banners',{attrs:{"data":_vm.errors}}),_c('InviteInput',{attrs:{"inputGroupData":_vm.formData.invite,"updateErrors":_vm.updateErrors,"stagedFromParent":this.invites,"inviteButtonText":"Add Users","showCounter":false},on:{"stage-invites":_vm.stageInvites},scopedSlots:_vm._u([{key:"helper",fn:function(){return [_vm._v(" All users will be added as "),_c('em',[_vm._v("members")]),_vm._v(", by default. There is a maximum of "+_vm._s(_vm.INVITE_MAX)+" employee invites at a time. ")]},proxy:true}])}),_c('table',{staticClass:"employer-profile__invite-list"},[(_vm.invites && _vm.invites.length > 0)?_c('thead',[_c('tr',[_c('th',{staticClass:"employer-profile__invite-header"},[_vm._v(_vm._s(_vm.$t('User')))]),_c('th',{staticClass:"employer-profile__invite-header"},[_vm._v(_vm._s(_vm.$t('Access Level')))])])]):_vm._e(),_c('tbody',_vm._l((_vm.invites),function(invite,idx){return _c('tr',{key:idx,staticClass:"employer-profile__invite-row"},[_c('td',[_c('p',{staticClass:"employer-profile__email"},[_vm._v(_vm._s(invite))])]),_c('td',{staticClass:"employer-profile__invite-name"},[_vm._v(" "+_vm._s(_vm.$t('Employee'))+" "),_c('NebulaIconButton',{staticClass:"employer-profile-remove",attrs:{"aria-label":"remove from list","icon":"circle-minus","type":"flat"},on:{"click":function($event){return _vm.removeInvite(invite)}}})],1)])}),0)]),_c('ProfileSetupActions',{attrs:{"nextText":_vm.nextText,"nextDisabled":_vm.invites.length > _vm.INVITE_MAX || _vm.invites.length < 1,"showBack":"","analyticsAttributes":{
            next: {
                'data-click-type': 'Profile Setup Workflow: Org Admin',
                'data-click-name': 'Invites: Send',
            },
            back: {
                'data-click-type': 'Profile Setup Workflow: Org Admin',
                'data-click-name': 'Invites: Back',
            }
        }},on:{"click-next":_vm.handleSaveAndContinue,"click-back":_vm.goBack},scopedSlots:_vm._u([{key:"nextButtons",fn:function(){return [_c('NebulaButton',{attrs:{"type":"ghost","text":_vm.$t('skip'),"data-click-type":"Profile Setup Workflow: Org Admin","data-click-name":"Invites: Skip"},on:{"click":function($event){return _vm.skip()}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }