<template>
    <div class="profile-setup-actions">
        <div v-if="showBack" class="profile-setup-actions__back-buttons">
            <NebulaButton
                type="flat"
                :text="backText || $t('back')"
                @click="handleBack"
                v-bind="analyticsAttributes.back"
            />
        </div>
        <div :class="nextButtonsClass">
            <slot name="nextButtons" />
            <NebulaButton
                :text="nextText"
                @click="handleNext"
                :isDisabled="nextDisabled"
                v-bind="analyticsAttributes.next"
            />
        </div>
    </div>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';

export default {
    name: 'ProfileSetupActions',
    components: {
        NebulaButton,
    },
    props: {
        backText: {
            type: String,
            default: 'Back',
        },
        nextText: {
            type: String,
            default: 'Save & Continue',
        },
        nextDisabled: Boolean,
        showBack: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: false,
        },
        analyticsAttributes: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        nextButtonsClass() {
            return [
                'profile-setup-actions__next-buttons',
                `profile-setup-actions__next-buttons--${this.center ? 'center' : 'end'}`,
            ];
        },
    },
    methods: {
        handleBack() {
            this.$emit('click-back');
        },
        handleNext() {
            this.$emit('click-next');
        },
    },
};
</script>

<style lang="stylus">
.profile-setup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: $nebula-space-5x;

    &__back-buttons {
        flex: 1;
    }

    &__next-buttons {
        display: flex;
        flex: 1;
        gap: $nebula-space-2x;
        &--end {
            justify-content: flex-end;
        }

        &--center {
            justify-content: center;
        }
    }
}
</style>
