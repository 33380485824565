<template>
    <div :class="className">
        <div class="profile-phase__header">
            <div v-if="avatarProps" class="profile-phase__image-wrapper">
                <ProfileAvatar :size="isMobile? 'l' : 'xl'" v-bind="avatarProps" />
                <NebulaButton
                    class="profile-phase__image-edit"
                    icon-left="edit"
                    @click="toggleUploadModal"
                    size="s"
                    :text="isMobile ? $t('Edit') : $t(`Edit ${nameOfAvatar}`)"
                    :aria-label="$t('Edit Logo')"
                    type="flat"
                />
            </div>
            <div class="profile-phase__header-content">
                <h1 class="profile-phase__title">{{ data.header }}</h1>
                <p class="profile-phase__description" v-if="this.$slots.description">
                    <slot name="description" />
                </p>
                <p class="profile-phase__description">{{ data.description }}</p>
            </div>
        </div>
        <div class="profile-phase__content" v-if="this.$slots.default">
            <slot />
        </div>
    </div>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

import { NebulaButton } from '@discoveryedu/nebula-components';
import breakpoints from '@/mixins/breakpoints';

export default {
    name: 'Phase',
    components: {
        NebulaButton,
        ProfileAvatar,
    },
    computed: {
        hasImage() {
            return Boolean(this.$slots.image);
        },
        className() {
            return [
                'profile-phase',
            ];
        },
        nameOfAvatar() {
            return this.isOrganization ? 'Logo' : 'Photo';
        },
    },
    mixins: [breakpoints],
    props: {
        data: {
            type: Object,
        },
        showBack: {
            type: Boolean,
            default: false,
        },
        editImagePath: {
            type: String,
            default: '/photo',
        },
        avatarProps: {
            type: Object,
        },
        isOrganization: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggleUploadModal() {
            this.$store.dispatch('toggleModal', {
                category: 'settings',
                type: 'upload_image',
                show: !this.$store.getters.avatarUploadModal.show,
            });

            this.$emit('upload-modal-opened');
        },
    },
};
</script>

<style lang='stylus'>
.profile-phase {
    max-width: 100vw;

    &__buttons {
        display: flex;
    }

    &__header {
        display: flex;
        gap: $nebula-space-3x;
        margin-bottom: $nebula-space-5x;
    }

    &__title {
        font-size: $nebula-font-size-header-level-4;
        font-weight: 700;
        line-height: 1em;
        margin: 0 0 $nebula-space-1x;
    }

    &__description {
        nebula-text-body-1();
        margin: $nebula-space-half 0 0;
        --nebula-text-color-body-default: $nebula-color-platform-neutral-900;
    }

    &__image-wrapper {
        & + .profile-phase__header-content {
            padding-top: 20px;
            @media (min-width: $nebula-breakpoints-desktop) {
                padding-top: 52px;
            }
        }
    }

    &__image {
        align-items: center;
        background-color: $nebula-color-platform-interface-300;
        border-radius: 50%;
        display: flex;
        height: 82px;
        justify-content: center;
        overflow: hidden;
        width: 82px;

        @media (min-width: $nebula-breakpoints-desktop) {
            width: 136px;
            height: 136px;
        }

        &-edit {
            margin: $nebula-space-1x auto 0;
        }
    }
}
</style>
