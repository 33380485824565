const timeSlots = [
    {
        startHour: 6,
        startMin: 0,
        endHour: 8,
        endMin: 0,
    },
    {
        startHour: 8,
        startMin: 0,
        endHour: 10,
        endMin: 0,
    },
    {
        startHour: 10,
        startMin: 0,
        endHour: 12,
        endMin: 0,
    },
    {
        startHour: 12,
        startMin: 0,
        endHour: 14,
        endMin: 0,
    },
    {
        startHour: 14,
        startMin: 0,
        endHour: 17,
        endMin: 0,
    },
    {
        startHour: 17,
        startMin: 0,
        endHour: 20,
        endMin: 0,
    },
];

export default timeSlots;
