<template>
    <div>
        <ErrorBanner
            v-if="this.showErrors && this.fieldsWithErrors.length > 0"
            header="Form Errors"
            description="Please resolve the form errors in order to proceed to the next step."
            color="red"
        />

        <ProfileFormSection :header="$t('Organization details')">
            <p class="employee-profile-setup__prepop-label">Organization</p>
            <p class="employee-profile-setup__prepop-value">{{ currentOrganization }}</p>
            <ProfileFormField
                name="headline"
                :inputGroupData="formData.headline"
                :validation="validateInput"
            />
            <ProfileFormField
                name="industry"
                :inputGroupData="formData.industry"
                :validation="validateInput"
                :selectOptions="industryOptions"
            />
        </ProfileFormSection>
        <ProfileFieldGroup horizontal>
            <ProfileFormField
                name="city"
                :inputGroupData="formData.city"
                :validation="validateInput"
                :flex="3"
                helperText="Enter your organization's local branch city."
            />
            <ProfileFormField
                name="state"
                :inputGroupData="formData.state"
                :validation="validateInput"
                :selectOptions="stateOptions"
            />
            <ProfileFormField
                name="zip"
                :inputGroupData="formData.zip"
                :validation="validateInput"
            />
        </ProfileFieldGroup>
        <ProfileFormField
            class="employer-profile-setup__description-input"
            name="description"
            inputType="textarea"
            :inputGroupData="formData.description"
            :validation="validateInput"
        />

        <ProfileFormSection v-if="!isActive">
            <ProfileFieldGroup
                padded
            >
                <p class="employee-profile-setup__prepop-label">
                    {{ $t('terms-and-conditions-label', { ns: 'termsconditions' }) }}
                    <span class="nebula-label__required">*</span>
                </p>
                <ProfileFormField
                    name="terms"
                    inputType="check-box"
                    :inputGroupData="formData.privacyChecked"
                    :validation="validateInput"
                >
                    <template v-slot:checkbox-label>
                        <CheckboxLabel
                            :text="$t('admin-terms-of-use-text', { ns: 'termsconditions' })"
                            :link="$t('terms-of-use-link', { ns: 'termsconditions' })"
                            :anchor="$t('terms-of-use-anchor', { ns: 'termsconditions' })"
                        />
                    </template>
                </ProfileFormField>
                <ProfileFormField
                    name="privacy"
                    inputType="check-box"
                    :inputGroupData="formData.termsChecked"
                    :validation="validateInput"
                >
                    <template v-slot:checkbox-label>
                        <CheckboxLabel
                            :text="$t('admin-privacy-policy-text', { ns: 'termsconditions' })"
                            :link="$t('privacy-policy-link', { ns: 'termsconditions' })"
                            :anchor="$t('privacy-policy-anchor', { ns: 'termsconditions' })"
                        />
                    </template>
                </ProfileFormField>
            </ProfileFieldGroup>
        </ProfileFormSection>
        <ProfileSetupActions
            @click-next="handleSaveAndContinue"
            :analyticsAttributes="{
                next: {
                    'data-click-type': 'Profile Setup Workflow: Org Admin',
                    'data-click-name': 'Org Details: Save & Continue',
                },
            }"
        />
    </div>
</template>

<script>
import ProfileFormSection from '@/components/shared/Profile/ProfileFormSection.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileFieldGroup from '@/components/shared/Profile/ProfileFieldGroup.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import CheckboxLabel from '@/components/shared/forms/CheckboxLabel.vue';
import ErrorBanner from '@/components/shared/ErrorBanner.vue';
import { industryOptions, stateOptions } from '@/data/formData';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'EmployerProfileStep1',
    components: {
        CheckboxLabel,
        ProfileFormField,
        ProfileFormSection,
        ProfileFieldGroup,
        ProfileSetupActions,
        ErrorBanner,
    },
    mixins: [createPayload],
    computed: {
        industryOptions,
        currentOrganization() {
            // TODO how do we detect the active organization if all users can be in multiple?
            return this.$store.state.app.organization.name;
        },
        loading() {
            return this.$store.state.status.organization.loading;
        },
        isActive() {
            return this.$store.state.app.organization.isActive;
        },
    },
    data() {
        return {
            stateOptions,
            showErrors: false,
            fieldsWithErrors: [],
            formData: {
                city: {
                    value: '',
                    display: this.$t('city'),
                    required: true,
                    error: null,
                    status: '',
                    placeholder: 'Enter city',
                },
                description: {
                    value: '',
                    display: 'Tell us about your organization. This will be included in the organization public profile for educators to view.',
                    required: false,
                    error: null,
                    status: '',
                    maxLength: 750,
                    placeholder: 'Write a short description...',
                },
                headline: {
                    value: '',
                    display: 'Headline',
                    required: false,
                    error: null,
                    status: '',
                    maxLength: 150,
                    placeholder: 'Enter a short headline',
                },
                industry: {
                    value: '',
                    display: 'Industry',
                    required: true,
                    error: null,
                    status: '',
                },
                state: {
                    value: '',
                    display: this.$t('state'),
                    required: true,
                    error: null,
                    status: '',
                },
                zip: {
                    value: '',
                    display: this.$t('zip-code'),
                    required: true,
                    error: null,
                    status: '',
                    placeholder: '12345',
                },
                termsChecked: {
                    value: false,
                    required: true,
                    error: null,
                    status: '',
                },
                privacyChecked: {
                    value: false,
                    required: true,
                    error: null,
                    status: '',
                },
            },
        };
    },
    methods: {
        isValid(key) {
            // apply other validation here
            const { value, required } = this.formData[key];
            if (required) {
                // return value.length > 0;
                return Boolean(value);
            }
            return true;
        },
        validateInput(key) {
            const passes = this.isValid(key);
            // 'status' is for the purpose of display state only
            // only update the status for an individual field if errors should be visible
            if (this.showErrors) {
                const errorString = 'error';

                this.formData[key].status = (!passes && errorString).toString();
            }

            this.formData[key].error = !passes;
        },
        validateAll() {
            const fields = Object.keys(this.formData);
            fields.forEach((field) => {
                this.validateInput(field);
            });

            return fields.filter((each) => this.formData[each].error);
        },
        async handleSaveAndContinue() {
            this.fieldsWithErrors = this.validateAll();
            const payload = await this.createPayload();

            if (this.fieldsWithErrors.length > 0) {
                this.showErrors = true;
                this.fieldsWithErrors.forEach((field) => {
                    this.formData[field].status = 'error';
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
                console.log(this.fieldsWithErrors);
            } else {
                const storeable = {};
                Object.keys(this.formData).forEach((field) => {
                    storeable[field] = this.formData[field].value;
                });

                // store content if validation passes
                this.$store.dispatch('updateOrganization', storeable);
                this.$store.dispatch('saveOrganization', payload);
                this.$emit('next-step');
            }
        },
        populateFields() {
            // populate formData from the store
            const fields = Object.keys(this.$store.state.app.organization);
            // these are only required (and shown) for initial onboarding
            const requiredForInactive = ['termsChecked', 'privacyChecked'];
            fields.forEach((each) => {
                if (this.formData[each]) {
                    this.formData[each].value = this.$store.state.app.organization[each];
                }
            });

            // on edit profile, update required fields as needed
            if (this.isActive) {
                requiredForInactive.forEach((key) => {
                    this.formData[key].required = false;
                });
            }

            this.validateAll();
        },
    },
    watch: {
        loading(loading) {
            if (!loading) {
                this.populateFields();
            }
        },
    },
    mounted() {
        this.populateFields();
    },
};
</script>

<style lang='stylus'>
.employer-profile-setup {
    &__description-input {
        textarea {
            height: 184px;
        }
    }
}
</style>
