<template>
    <NebulaPageWrapper padded contained>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
            >
                <Stepper
                    @update-current-step="updateCurrent"
                    class="profile-setup__stepper"
                    :parentActive="active"
                    :steps="steps"
                    :showIndex="true"
                    :completed="stepsCompleted"
                />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
                v-show="active === 0"
            >
                <Phase
                    class="employer-profile-setup__phase"
                    :data="{
                        header: $t('setup-title', { ns: 'profile' }),
                        description: $t('instruction', { ns: 'profile' }),
                        nextText: $t('save-continue'),
                    }"
                    :avatarProps="{
                        image: avatarImage,
                        icon: 'buildings',
                    }"
                    :isOrganization="true"
                >
                    <EmployerProfileStep1 @next-step="updateCurrent()" />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
                v-show="active === 1"
            >
                <Phase
                    class="employer-profile-setup__phase"
                    :data="{
                        header: $t('add-users', { ns: 'profile' }),
                        nextText: $t('save-continue'),
                        description:
                            `Get started by inviting employees (or members for professional organizations) to join your organization's
                            account. You can manage this list later from the User Management page.`,
                    }"
                >
                    <EmployerProfileStep2 @previous-step="goBack" @skip="updateCurrent()"
                                          @next-step="updateCurrent()"
                    />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaPageWrapper v-show="active === 2">
            <NebulaGridRow>
                <NebulaGridBlock
                    :columns="6"
                    :offset="3"
                    :mdSize="6"
                    :mdOffset="1"
                    :smSize="4"
                >
                    <Phase
                        class="employer-profile-setup__phase"
                        :data="{
                            header: $t('your-company-profile', { ns: 'profile' }),
                        }"
                    >
                        <template v-slot:description>
                            {{ $t(
                            `Please review your public facing profile before saving. You can manage and edit your profile information at any time in`,
                        ) }}
                            <a href="#" class="employer-profile__link">{{ $t('Settings & Preferences') }}.</a>
                        </template>
                        <ProfileSetupActions
                            @click-next="saveProfile"
                            :nextText="$t('Save')"
                            center
                            :analyticsAttributes="{
                                next: {
                                    'data-click-type': 'Profile Setup Workflow: Org Admin',
                                    'data-click-name': 'Profile Confirmation: Save',
                                },
                            }"
                        >
                            <template v-slot:nextButtons>
                                <NebulaButton
                                    type="ghost"
                                    :text="$t('Edit Profile')"
                                    @click="goBack(0)"
                                    icon-right="edit"
                                    data-click-type="Profile Setup Workflow: Org Admin"
                                    data-click-name="Profile Confirmation: Edit Profile"
                                />
                            </template>
                        </ProfileSetupActions>
                    </Phase>
                </NebulaGridBlock>
            </NebulaGridRow>
            <NebulaGridRow class="employee-profile-setup__profile" gutter>
                <NebulaGridBlock :columns="12" class="employer-profile-setup__profile-panel">
                    <CompanyProfile
                        preview
                        :data="orgData"
                    />
                </NebulaGridBlock>
            </NebulaGridRow>
        </NebulaPageWrapper>
    </NebulaPageWrapper>
</template>

<script>
import { NebulaButton, NebulaGrid } from '@discoveryedu/nebula-components';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import createPayload from '@/mixins/data/createPayload';

import CompanyProfile from '@/components/dashboard/CompanyProfile.vue';
import Stepper from '@/components/shared/stepper/Stepper.vue';
import Phase from '@/components/shared/Profile/Phase.vue';
import EmployerProfileStep1 from '@/components/Profile/EmployerProfileStep1.vue';
import EmployerProfileStep2 from '@/components/Profile/EmployerProfileStep2.vue';

export default {
    name: 'EmployerProfileSetup',
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaButton,
        Stepper,
        Phase,
        EmployerProfileStep1,
        EmployerProfileStep2,
        ProfileSetupActions,
        CompanyProfile,
    },
    mixins: [createPayload],
    computed: {
        orgData() {
            return this.$store.state.app.organization;
        },
        avatarImage() {
            const { avatarUrl } = this.$store.state.app.organization;
            return avatarUrl;
        },
    },
    data() {
        return {
            active: 0,
            steps: 3,
            stepsCompleted: [],
        };
    },
    methods: {
        updateCurrent(idx) {
            this.stepsCompleted.push(this.active);
            if (idx !== undefined) {
                this.active = idx;
            } else if (this.active < this.steps - 1) {
                this.active += 1;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async saveProfile() {
            const payload = await this.createPayload();
            await this.$store.dispatch('activateOrganization', payload);
            this.$router.push('/');
        },
        goBack(idx) {
            if (idx !== undefined) {
                this.active = idx;
            } else if (this.active >= 1) {
                this.active -= 1;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style lang='stylus'>
.employer-profile-setup {
    &__phase {
        margin-block-start: $nebula-space-7x;
    }
    &__profile-panel {
        card-base();
    }
}
</style>
