<template>
   <NebulaPageWrapper padded contained>
        <NebulaGridRow>
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
            >
                <Phase
                    class="employee-profile-setup__phase"
                    :data="{
                        header: 'Profile Setup',
                        description: 'Update your photo and profile details here.',
                    }"
                    :avatarProps="{
                        image: avatarImage
                    }"
                    @upload-modal-opened="handleUploadOpened"
                >
                    <ErrorBanner
                        v-if="this.showErrors && this.fieldsWithErrors.includes('termsChecked') || this.fieldsWithErrors.includes('privacyChecked')"
                        header="Errors"
                        description="You must accept the terms and conditions to sign up for an account."
                        color="red"
                    />
                    <ProfileFormSection header="Personal Details">
                        <ProfileFieldGroup horizontal>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">First Name</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.firstName }}</p>
                            </div>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">Last Name</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.lastName }}</p>
                            </div>
                        </ProfileFieldGroup>
                        <ProfileFieldGroup horizontal>
                            <ProfileFormField
                                name="preferredName"
                                :inputGroupData="formData.preferredName"
                                :validation="validateInput"
                                helperText="This will appear as your first name on your profile."
                            />
                            <ProfileFormField
                                name="pronoun"
                                :inputGroupData="formData.pronoun"
                                :validation="validateInput"
                                :selectOptions="pronounsOptions"
                            />
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileFormSection header="Professional Details">
                        <div class="educator-profile-setup__prepop-group">
                            <p class="educator-profile-setup__prepop-label">School</p>
                            <p class="educator-profile-setup__prepop-value">{{ data.site.site_name }}</p>
                        </div>
                        <ProfileFieldGroup horizontal>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">City</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.site.site_city }}</p>
                            </div>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">State</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.site.site_state }}</p>
                            </div>
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileFormSection v-if="!data.privacyChecked || !data.termsChecked">
                        <ProfileFieldGroup>
                            <p class="educator-profile-setup__terms-label">{{ $t('terms-and-conditions-label', { ns: 'termsconditions' }) }}</p>
                            <ProfileFormField
                                name="privacyChecked"
                                inputType="check-box"
                                :inputGroupData="formData.termsChecked"
                                :validation="validateInput"
                            >
                                <template v-slot:checkbox-label>
                                    <CheckboxLabel
                                        :text="$t('educator-terms-text', { ns: 'termsconditions' })"
                                        :link="$t('terms-of-use-link', { ns: 'termsconditions' })"
                                        :anchor="$t('terms-of-use-anchor', { ns: 'termsconditions' })"
                                    />
                                </template>
                            </ProfileFormField>
                            <ProfileFormField
                                name="privacyChecked"
                                inputType="check-box"
                                :inputGroupData="formData.privacyChecked"
                                :validation="validateInput"
                            >
                                <template v-slot:checkbox-label>
                                    <CheckboxLabel
                                        :text="$t('educator-privacy-text', { ns: 'termsconditions' })"
                                        :link="$t('privacy-policy-link', { ns: 'termsconditions' })"
                                        :anchor="$t('privacy-policy-anchor', { ns: 'termsconditions' })"
                                    />
                                </template>
                            </ProfileFormField>
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileSetupActions
                        nextText="Save"
                        @click-next="handleSaveAndContinue"
                        :analyticsAttributes="{
                            next: {
                                'data-click-type': 'Profile Setup Workflow: Educator',
                                'data-click-name': 'Educator Details: Save & Continue',
                            },
                        }"
                    />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import Phase from '@/components/shared/Profile/Phase.vue';
import ProfileFieldGroup from '@/components/shared/Profile/ProfileFieldGroup.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileFormSection from '@/components/shared/Profile/ProfileFormSection.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import CheckboxLabel from '@/components/shared/forms/CheckboxLabel.vue';
import ErrorBanner from '@/components/shared/ErrorBanner.vue';
import createPayload from '@/mixins/data/createPayload';

import validation from '@/mixins/form/validation';
import {
    pronounsOptions,
} from '@/data/formData';

export default {
    name: 'EducatorProfileSetup',
    components: {
        CheckboxLabel,
        ErrorBanner,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        Phase,
        ProfileFormSection,
        ProfileFormField,
        ProfileFieldGroup,
        ProfileSetupActions,
    },
    mixins: [validation, createPayload],
    computed: {
        pronounsOptions,
        avatarImage() {
            const { avatarUrl } = this.$store.state.user.data;
            return avatarUrl;
        },
        data() {
            return this.$store.state.user.data;
        },
    },
    data() {
        return {
            formData: {
                preferredName: {
                    value: '',
                    display: 'Preferred Name',
                    required: false,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                pronoun: {
                    value: '',
                    display: 'Pronouns',
                    required: false,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                privacyChecked: {
                    value: false,
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                termsChecked: {
                    value: false,
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
            },
        };
    },
    methods: {
        async saveAction(storeable) {
            // this method is called in the handleSaveAndContinue method from the validation mixin
            let payload = await this.createPayload();

            let changed = false;
            const compareFields = ['pronoun', 'privacyChecked', 'termsChecked', 'preferredName'];

            compareFields.forEach((field) => {
                if (this.data[field] !== this.formData[field].value) {
                    changed = true;
                }
            });

            if (!this.data.isActive) {
                payload = await this.createPayload({ activate: true });
            }

            if (changed || !this.data.isActive) {
                await this.$store.dispatch('updateUserData', storeable);
                await this.$store.dispatch('updateEducator', payload);
            }

            this.$router.push('/');
        },
        async handleUploadOpened() {
            if (this.$store.state.user.data.notCreated) {
                const { email, id } = this.$store.state.user.data;
                const baseData = { email, id };
                const payload = await this.createPayload(baseData);
                await this.$store.dispatch('updateEducator', payload);
            }
        },
    },
    mounted() {
        this.populateFields(['pronoun'], null);
    },
};
</script>

<style lang="stylus">
.educator-profile-setup {
    &__prepop-label {
        prepop-label();
    }

    &__terms-label {
        prepop-label();
        margin-block-end: $nebula-space-2x;
    }

    &__prepop-value {
        prepop-value();
    }
    &__prepop-group {
        flex: 1;
        .educator-profile-setup__prepop-label {
            margin-block-start: 0;
        }
    }
}
</style>
