<template>
    <section>
        <div class="availability-new__head">
            <h2 class="availability-new__header">{{ $t('Dates & Times') }}</h2>
            <TimezoneSelect
                @timezone-changed="updateUserTimezone"
                class="availability-new__timezone-select"
            />
        </div>
        <div
            :class="['availability-new__column-container',
                {'availability-new__column-container-simple': !this.showIcons}
            ]"
        >
            <h3 class="availability-new__inner-title">
                My Availability
            </h3>
            <AvailabilityControls :visibleDays="visibleDays" @prev="prevDay" @next="nextDay" />
            <div v-if="showIcons" class="availability-new__icon-column">
                <div class="availability-new__icon-block" v-for="(slot, idx) in timeSlots" :key="idx">
                    <NebulaIcon class="availability-new__icon" :symbolId="timeIconsMap[slot.startHour]" />
                </div>
            </div>
            <div v-if="showIcons" class="availability-new__hidden-mobile" />
            <h3
                :class="[
                    'availability-new__column-header',
                    { 'availability-new__hidden-mobile': !visibleInMobile(x) }
                ]"
                v-for="x in 5" :key="dayOfWeekMap[x - 1]"
            >
                {{ dayOfWeekMap[x - 1] }}
            </h3>
            <div
                :class="[
                    'availability-new__column',
                    { 'availability-new__hidden-mobile': !visibleInMobile(day) }
                ]"
                v-for="day in 5"
                :key="day"
            >
                <div
                    class="availability-new__button-wrapper"
                    v-for="(slot, idx) in timeSlots"
                    :key="idx"
                >
                    <AvailabilityButton
                        @click.native="toggleTimeslot(slot, day - 1)"
                        :selected="isSelected(slot, day - 1)"
                        :timeSlot="slot"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import TimezoneSelect from '@/components/shared/forms/TimezoneSelect.vue';
import timeSlots from '@/data/timeSlots';
import AvailabilityButton from './AvailabilityButton.vue';
import AvailabilityControls from './AvailabilityControls.vue';

export default {
    name: 'GeneralAvailability',
    components: {
        NebulaIcon,
        TimezoneSelect,
        AvailabilityButton,
        AvailabilityControls,
    },
    props: {
        availability: {
            type: Array,
        },
        showIcons: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            timeSlots,
            timeIconsMap: {
                6: 'time-early-morning',
                8: 'time-late-morning',
                10: 'time-morning',
                12: 'time-afternoon',
                14: 'time-late-afternoon',
                17: 'time-night',
            },
            dayOfWeekMap: {
                0: 'Monday',
                1: 'Tuesday',
                2: 'Wednesday',
                3: 'Thursday',
                4: 'Friday',
            },
            visibleDays: [
                1, 2, 3,
            ],
        };
    },
    computed: {
    },
    methods: {
        toggleTimeslot(timeSlot, day) {
            const combinedTimeSlotOption = {
                ...timeSlot,
                ...{
                    dayOfWeek: day,
                },
            };
            const currentlySelected = this.isSelected(timeSlot, day);

            if (currentlySelected) {
                this.$emit('deselect-timeslot', combinedTimeSlotOption);
            } else {
                this.$emit('select-timeslot', combinedTimeSlotOption);
            }
        },
        isSelected(timeSlot, day) {
            let matchFound;
            const keyOrder = [
                'startHour',
                'startMin',
                'dayOfWeek',
            ];

            const combinedTimeSlotOption = {
                ...timeSlot,
                ...{
                    dayOfWeek: day,
                },
            };

            // loop through stored availability to see if there's a match
            this.availability.forEach((each) => {
                const availabilityString = keyOrder.map((key) => each[key]).join(',').trim();
                const optionString = keyOrder.map((key) => combinedTimeSlotOption[key]).join(',').trim();

                if (availabilityString === optionString) {
                    matchFound = true;
                }
            });

            return matchFound;
        },
        visibleInMobile(day) {
            return this.visibleDays.includes(day);
        },
        prevDay() {
            const highest = Math.max(...this.visibleDays);
            const lowest = Math.min(...this.visibleDays);
            if (lowest > 1) {
                this.visibleDays.unshift(lowest - 1);
                const toRemove = this.visibleDays.indexOf(highest);
                this.visibleDays.splice(toRemove, 1);
            }
        },
        nextDay() {
            const highest = Math.max(...this.visibleDays);
            const lowest = Math.min(...this.visibleDays);
            if (highest < 5) {
                this.visibleDays.push(highest + 1);
                const toRemove = this.visibleDays.indexOf(lowest);
                this.visibleDays.splice(toRemove, 1);
            }
        },
        updateUserTimezone(selectedTimezone) {
            this.$emit('user-updated');
            this.$store.dispatch('updateUserData', { timezone: selectedTimezone });
        },
    },
};
</script>

<style lang="stylus">
.availability-new {
    column-container() {
        card-base();
        display: grid;
        flex: 1;
        gap: $nebula-space-1x;
        grid-template-columns: repeat(3, 1fr);
        margin-block-start: $nebula-space-4x;
        padding: $nebula-space-2x;
        @media (min-width: $nebula-breakpoints-desktop) {
            override-card-base();
            padding: 0;
        }
    }

    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: $nebula-breakpoints-desktop) {
            flex-wrap: nowrap;
        }
    }
    &__header {
        nebula-text-responsive-h6();
        margin: 0;
        flex-basis: 100%;
        @media (min-width: $nebula-breakpoints-desktop) {
            flex-basis: auto;
        }
    }
    &__hidden-mobile {
        display: none;
        @media (min-width: $nebula-breakpoints-desktop) {
            display: block;
        }
    }
    &__grid {
        display: flex;
    }
    &__column-container {
        column-container();
        @media (min-width: $nebula-breakpoints-desktop) {
            grid-template-columns: auto repeat(5, 1fr);
        }
    }
    &__column-container-simple {
        column-container();
        @media (min-width: $nebula-breakpoints-desktop) {
            grid-template-columns: repeat(5, 1fr);
        }
    }
    &__column {
        display: grid;
        grid-auto-rows: 1fr;
        row-gap: $nebula-space-2x;
        &.availability-new__hidden-mobile {
            display: none;
            @media (min-width: $nebula-breakpoints-desktop) {
                display: grid;
            }
        }
    }
    &__column-header {
        text-align: center;
        margin: 0 0 $nebula-space-2x;
    }
    &__icon-column {
        display: none;
        fill: $nebula-color-platform-interface-700;
        flex: 0;
        grid-auto-rows: 1fr;
        grid-row-start: 2;
        row-gap: $nebula-space-2x;
        padding-inline: $nebula-space-half;
        @media (min-width: $nebula-breakpoints-desktop) {
            display: grid;
        }
    }
    &__icon-block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__inner-title {
        nebula-text-responsive-h6();
        grid-column-start: span 3;
        margin: 0;
        @media (min-width: $nebula-breakpoints-desktop) {
            display: none;
        }
    }
    &__controls {
        grid-column-start: span 3;
        display: flex;
        justify-content: space-between;
        margin-block: $nebula-space-3x;
        @media (min-width: $nebula-breakpoints-desktop) {
            display: none;
        }
    }
    &__control-button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        gap: $nebula-space-1x;
        &--disabled {
            svg {
                fill: $nebula-color-platform-neutral-400;
            }
            color: $nebula-color-platform-neutral-400;
        }
    }
    &__control-button-text {
        font-weight: 700;
    }
    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__button-positioner {
        display: flex;
        justify-content: center;
        flex: 1;
    }
    &__timezone-select {
        margin-block-start: $nebula-space-3x;
        @media (min-width: $nebula-breakpoints-desktop) {
            margin-block-start: 0;
        }
    }

}

.general-availability {
    &__horizontal-field {
        display: flex;
        align-items: center;
        margin: 0;
        width: unset;
        .nebula-label {
            margin-bottom: 0;
            margin-right: $nebula-space-1x;
        }
    }
}
</style>
